<template>
    <div class="index">
        <index-menu></index-menu>
        <index-right></index-right>
    </div>
</template>
<script>

import menu from '../components/menu.vue';
import right from '../components/right.vue';
export default {
    data(){
        return{
            aa:'aa'
        }
    },
    components:{
        'indexMenu' : menu,
        'indexRight' : right
    }
}
</script>
<style>
    *{margin: 0; padding: 0;}
    li{list-style: none;}
    a{text-decoration: none;}
    .index{ width: 100%; display: flex; flex-direction: row; justify-content: space-between;}

</style>