<template>
  <div class="menu">
    <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b">
      <el-menu-item index="1" @click="backhome()"><i class="el-icon-s-home"></i><span>主页</span></el-menu-item>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>新闻栏目</span>
        </template>
        <el-menu-item index="item.id" v-for="(item,index) in artile_type_list"
            :key="index" @click="articleList(item.id)">{{item.title}}</el-menu-item>
      </el-submenu>
      <el-menu-item index="3" @click="addArticle()"><i class="el-icon-folder-add"></i><span>添加文章</span></el-menu-item>
      <el-menu-item index="4" @click="logout()"><i class="el-icon-s-release"></i><span>退出登录</span></el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import axios from 'axios'
const baseurl = process.env.VUE_APP_BASE_API
export default {
  inject:['routerRefresh'],   //在子组件中注入在父组件中创建的属性
  data(){
    return{
      artile_type_list : [],
      artile_type : ''
    }
  },
  methods:{
    articleList(id){
      this.$router.push(`/index/articleList/${id}`);
      this.routerRefresh();//调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    backhome(){
        this.$router.push('/index/index');
    },
    addArticle(){
        this.$router.push('/index/add');
    },
    logout(){
      localStorage.removeItem("islogin");
      this.$router.push('/Login');
    }
  },
  mounted (){
    axios({
        method: 'post',
        url: `${baseurl}/api/menu`,
        data:{}
      }).then(res => {
        if(res.data.code==2000)
        {
          this.artile_type_list = res.data.data;
        }
        else{
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          });
        }
    });
  }
}
</script>

<style>
.menu{ width: 12vw; background: #ccc;}
.menu-list{ width: 100%;}
.menu-list li{text-align: center; display: flex; flex-direction: row; align-items: center; justify-content: center; font-size: 16px;}
</style>