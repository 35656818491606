<template>
    <div>
        <div class="position">{{menuName}}</div>
        <ul>
            <li class="article-list" v-for="(item,index) in artile_list" :key="index">
                <div class="article-id">{{item.id}}</div> 
                <div class="article-img"><img :src="item.img"></div>
                <div @click="detail(item.id)" class="article-title">{{item.title}}</div>
                <div class="article-time">{{item.create_time}}</div>
                <div class="article-control">
                    <el-button
                    size="mini"
                    @click="edit(item.id)">编辑</el-button>
                    <el-popconfirm @confirm="del(item.id)" title="确定删除吗？">
                        <el-button slot="reference" size="mini" type="danger">删除</el-button>
                    </el-popconfirm>
                </div>
            </li>
        </ul>
        <div class="page">
            <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="page.pageCurrent"
                :page-size="page.pagesize"
                layout="prev, pager, next"
                :total="page.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
const baseurl = process.env.VUE_APP_BASE_API
export default {
    name: 'async',
    metaInfo () {
      return {
        title: `${this.menuName}-沈阳欧米奇`,
        meta: [{                 // set meta
            name: 'keywords',
            content: '沈阳欧米奇'
        },{                 // set meta
            name: 'description',
            content: '沈阳欧米奇'
        }]
      }
    },
    data(){
        return{
            artile_list :[],
            mid : this.$route.params.mid,
            page:{
                pagesize:2,
                pageCurrent:1,
                total:100
            },
            menuName:''
        }
    },
    methods:{
        add(){
            this.$router.push('/index/add');
        },
        edit(id){
            this.$router.push(`/index/edit/${id}`);
        },
        detail(id){
            this.$router.push(`/article/${id}`);
        },
        del(id) {
            axios({
                method: 'post',
                url: `${baseurl}/api/delete`,
                data:{
                    "id":id
                }
                }).then(res => {    
                    if(res.data.code==2000)
                    {
                        this.$message({
                            showClose: true,
                            message: res.data.msg,
                            type: 'success'
                        });
                        this.getArticle();
                        //this.artile_list = res.data.data.data;
                        //this.page.total = res.data.data.total;
                    }
                    else{
                        this.$message({
                            showClose: true,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
            });
        }, 
        handleCurrentChange(page) {
            //this.page.pageCurrent = page; //赋值页码
            axios({
                method: 'post',
                url: `${baseurl}/api/article_list`,
                data:{
                    "mid" : this.mid,
                    "pageSize":this.page.pagesize,
                    "pageNum":page
                }
                }).then(res => {
                if(res.data.code==2000)
                {
                    this.artile_list = res.data.data.data;
                    this.page.total = res.data.data.total;
                }
                else{
                    this.$message({
                        showClose: true,
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });
        }, 
        getArticle(){
            axios({
            method: 'post',
            url: `${baseurl}/api/article_list`,
            data:{
                "mid" : this.mid,
                "pageSize":this.page.pagesize,
                "pageNum":this.page.pageCurrent
            }
            }).then(res => {
                if(res.data.code==2000)
                {
                    this.artile_list = res.data.data.data;
                    this.page.total = res.data.data.total
                }
                else{
                    this.$message({
                        showClose: true,
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });
        } 
    },
    mounted (){
        //获取文章列表
        this.getArticle();

        //获取当前栏目名称
        axios({
            method: 'post',
            url: `${baseurl}/api/menu`,
            data:{
                "id" : this.mid
            }
        }).then(res => {
            if(res.data.code==2000)
            {
                this.menuName = res.data.data.title;
            }
            else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    type: 'error'
                });
            }
        });
    }
    
}
</script>
<style scoped>

</style>