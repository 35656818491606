<template>
  <div id="edit">
    <el-form  :label-position="labelPosition"  label-width="4.5vw" class="demo-ruleForm">
      <el-form-item label="文章标题">
        <el-input v-model="addForm.article_title" placeholder="文章标题"></el-input>
      </el-form-item>
      <el-form-item label="新闻栏目">
        <el-select v-model="artile_type" placeholder="新闻栏目">
          <el-option
            v-for="(item,index) in artile_type_list"
            :key="index"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键词">
        <el-input v-model="addForm.article_keywords" placeholder="关键词，用','号分开"></el-input>
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="addForm.article_description" placeholder="描述少于100个汉字"></el-input>
      </el-form-item>
      <el-form-item label="阅读数">
        <el-input v-model="addForm.article_click" ></el-input>
      </el-form-item>
      <TinymceEditor v-model="msg"></TinymceEditor>
      <el-button type="primary" @click="save">提交</el-button>
    </el-form>      
  </div>
</template>

<script>
import axios from 'axios'
const baseurl = process.env.VUE_APP_BASE_API
export default {
  name: 'async',
    metaInfo () {
      return {
        title: `${this.addForm.article_title}-沈阳欧米奇`,
        meta: [{                 // set meta
            name: 'keywords',
            content: this.addForm.article_keywords
        },{                 // set meta
            name: 'description',
            content: this.addForm.article_description
        }]
      }
  },
  data(){
    return{
      addForm:{
					article_title:'',
          article_keywords:'',
          article_description:'',
          article_click:''
			},
      artile_type_list:[],
      artile_type: '',
      msg:'',
      editId :this.$route.params.id,
      labelPosition: 'left'
    }
  },
  methods:{
    save() {
      if(!this.addForm.article_title) {
        this.$message({
          showClose: true,
          message: '文章标题不能为空',
          type: 'warning'
        });
        return false
      }
      if(!this.artile_type) {
        this.$message({
          showClose: true,
          message: '文章分类不能为空',
          type: 'warning'
        });
        return false
      }
      if(!this.addForm.article_keywords) {
        this.$message({
          showClose: true,
          message: '文章关键词不能为空',
          type: 'warning'
        });
        return false
      }
      if(!this.addForm.article_description) {
        this.$message({
          showClose: true,
          message: '文章描述不能为空',
          type: 'warning'
        });
        return false
      }
      if(!this.addForm.article_click) {
        this.$message({
          showClose: true,
          message: '文章阅读数不能为空',
          type: 'warning'
        });
        return false
      }
      else{
        axios({
          method: 'post',
          url: `${baseurl}/api/save`,
          data: {
              "title":this.addForm.article_title,
              "keywords":this.addForm.article_keywords,
              "description":this.addForm.article_description,
              "click":this.addForm.article_click,
              "mid":this.artile_type,
              "contents":this.msg,
              "id":this.editId
          }
          }).then(res => {
                    //let res_json = JSON.parse(res);
					if(res.data.code==2000)
					{
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'success'
            });
            this.$router.push('/index/index');
					}
					else{
						this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            });
					}
        });
      }
    }
  },
  mounted (){
    
    axios({
          method: 'post',
          url: `${baseurl}/api/menu`,
          data:{}
          }).then(res => {
            if(res.data.code==2000)
            {
              this.artile_type_list = res.data.data;
            }
            else{
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              });
            }
    });
    axios({
          method: 'post',
          url: `${baseurl}/api/edit`,
          data:{
            "id" : this.editId
          }
          }).then(res => {
                    //let res_json = JSON.parse(res);
                    
            if(res.data.code==2000)
            {
              //localStorage.setItem('islogin','islogin');
              this.addForm.article_title = res.data.data.title;
              this.addForm.article_keywords = res.data.data.keywords;
              this.addForm.article_description = res.data.data.description;
              this.addForm.article_click = res.data.data.click;
              this.artile_type = res.data.data.mid;
              this.msg = res.data.data.contents;
            }
            else{
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              });
            }
    });
  }

}
</script>

<style>
#edit{ padding-top: 1vw;}
</style>