<template>
  <div id="add">
    <el-form ref="form" label-width="4.5vw" :label-position="labelPosition">
      <el-form-item label="文章标题:">
        <el-input v-model="addForm.article_title" placeholder="文章标题"></el-input>
      </el-form-item>
      <el-form-item label="新闻栏目:">
        <el-select v-model="artile_type" placeholder="新闻栏目">
          <el-option
            v-for="(item,index) in artile_type_list"
            :key="index"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键词:">
        <el-input v-model="addForm.article_keywords" placeholder="关键词，用','号分开"></el-input>
      </el-form-item>
      <el-form-item label="描述:">
        <el-input v-model="addForm.article_description" placeholder="描述少于100个汉字"></el-input>
      </el-form-item>
      <el-form-item label="点击数:">
        <el-input v-model="addForm.article_click" ></el-input>
      </el-form-item>
    </el-form>
    <TinymceEditor v-model="msg"></TinymceEditor>  
    <el-button type="primary" @click="onSubmit">提交</el-button>
        
        
        

        
  </div>
</template>

<script>
import axios from 'axios'
const baseurl = process.env.VUE_APP_BASE_API
export default {
  metaInfo: {
      title: '新闻添加-沈阳欧米奇', // set a title
      meta: [{                 // set meta
        name: 'keywords',
        content: '沈阳欧米奇'
      },{                 // set meta
        name: 'description',
        content: '沈阳欧米奇'
      }]
  },
  data(){
    return{
      addForm:{
					article_title:'',
          article_keywords:'',
          article_description:'',
          article_click:'100'
			},
      artile_type_list:[],
      artile_type: '',
      msg:'',
      labelPosition: 'left'
    }
  },
  methods:{
    onSubmit() {
      if(!this.addForm.article_title) {
        this.$message({
          showClose: true,
          message: '文章标题不能为空',
          type: 'warning'
        });
        return false
      }
      if(!this.artile_type) {
        this.$message({
          showClose: true,
          message: '文章分类不能为空',
          type: 'warning'
        });
        return false
      }
      if(!this.addForm.article_keywords) {
        this.$message({
          showClose: true,
          message: '文章关键词不能为空',
          type: 'warning'
        });
        return false
      }
      if(!this.addForm.article_description) {
        this.$message({
          showClose: true,
          message: '文章描述不能为空',
          type: 'warning'
        });
        return false
      }
      if(!this.addForm.article_click) {
        this.$message({
          showClose: true,
          message: '文章阅读数不能为空',
          type: 'warning'
        });
        return false
      }
      else{
        axios({
          method: 'post',
          url: `${baseurl}/api/add`,
          data: {
              "title":this.addForm.article_title,
              "keywords":this.addForm.article_keywords,
              "description":this.addForm.article_description,
              "click":this.addForm.article_click,
              "mid":this.artile_type,
              "contents":this.msg
          }
          }).then(res => {
                    //let res_json = JSON.parse(res);
                    
					if(res.data.code==2000)
					{
            
						this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'success'
            });
            this.$router.push('/index/index');
					}
					else{
						this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            });
					}
        });
      }
    }
  },
  mounted (){
    axios({
          method: 'post',
          url: `${baseurl}/api/menu`,
          data:{}
          }).then(res => {
                    //let res_json = JSON.parse(res);
                    
            if(res.data.code==2000)
            {
              //localStorage.setItem('islogin','islogin');
              this.artile_type_list = res.data.data;
            }
            else{
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              });
            }
    });
  }
}
</script>

<style>
#add{ padding-top: 1vw;}
</style>