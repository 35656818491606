<template>
    <div class="right">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style>
.right{ width: 82vw; padding: 0 2vw;}
</style>